import React, { useEffect, ReactElement } from 'react';
import { ThemeProvider } from 'styled-components';

import { ThemeUtils, Maintenance, StyleSheetManager } from '@rentecarlo/component-library';
import { useOptimizelyFlag } from 'hooks';

import Router from './router';
import 'styles/reset.css';

import { ComponentProps as Props } from './App.container';

const { Veygo2024Theme } = ThemeUtils;

const App = (props: Props): ReactElement => {
  const showMaintenancePage = useOptimizelyFlag('PERM_RAISE_AUTH_MAINTENANCE_PAGE');

  const { setRedirectUrl } = props;
  useEffect(() => {
    if (window.location.search) {
      setRedirectUrl(window.location.search);
    }
  });
  return (
    <StyleSheetManager enableVendorPrefixes>
      {showMaintenancePage.enabled ? (
        <Maintenance />
      ) : (
        <ThemeProvider theme={Veygo2024Theme}>
          <Router />
        </ThemeProvider>
      )}
    </StyleSheetManager>
  );
};

export default App;
