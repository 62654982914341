import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Image = styled.img`
  flex: 1;
`;

export const Text = styled.div`
  margin-top: 24px;
  font-family: proxima-soft;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${({ theme }): string => theme.textPrimary};
`;
